
import { defineComponent, ref } from "vue";
import base from "@/api/base.js";
import { ErrorMessage, Field, Form } from "vee-validate";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import * as Yup from "yup";

interface Filter {
  status: string;
  code: string;
}

export default defineComponent({
  name: "businesses",

  components: {
    ErrorMessage,
    Field,
    Form,
    VPagination
  },

  data() {
    const validation = Yup.object().shape({
      bsns_nm: Yup.string().required("필수값입니다."),
      bsns_rep_nm: Yup.string().required("필수값입니다."),
      bsns_addr: Yup.string().required("필수값입니다."),
      bsns_licnbr: Yup.string()
        .matches(/^\d{10}$/, "-를 제외한 10자리의 숫자를 입력하세요.")
        .required("필수값입니다."),
      bsns_email: Yup.string()
        .required("필수값입니다.")
        .email("email 형식이 아닙니다."),
      bsns_use_yn: Yup.boolean().required("필수값입니다.")
    });

    return {
      businesses: [],
      businessData: {},
      business: {},
      searchcode: "bsns_nm",
      searchdata: "",
      status: "",
      page: 1,
      validation
    };
  },

  created() {
    this.getListData();
  },

  methods: {
    showApi() {
      const daum = (window as any).daum;
      new daum.Postcode({
        oncomplete: data => {
          this.business.bsns_addr = data.roadAddress;
        }
      }).open();
    },

    getSearchResult() {
      this.page = 1;
      this.getListData();
    },
    getListData(data) {
      const param = {
        [this.searchcode]: this.searchdata,
        bsns_use_yn: this.status,
        page: this.page
      };
      base
        .getBusinessesList(param)
        .then(res => {
          console.log(res);
          this.businesses = res.data;
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    getData(id) {
      base
        .getBusiness(id)
        .then(res => {
          console.log(res);
          this.businessData = res.data;
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    async editData() {
      base
        .updateBusiness(this.businessData)
        .then(res => {
          console.log(res);
          if (res.status == 200) {
            alert("사용자 정보를 수정했습니다.");
            this.modalopen = false;
            window.location.reload();
          } else {
            alert(res.status);
          }
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    async submitForm() {
      console.log(this.business);
      base
        .newBusiness(this.business)
        .then(res => {
          console.log(res);

          if (res.status == 201) {
            alert("신규 사업자를 등록했습니다.");
            window.location.reload();
          } else {
            alert(res.data.errors);
          }
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
      this.clearForm();
    }
  },

  clearForm() {
    for (const key in this.user) {
      this.user[key] = "";
    }
  },

  setup() {
    const data = ref<Filter>({
      status: "1",
      code: "1"
    });

    return {
      data
    };
  }
});
